import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import FormC from './Form.vue'

const Form = class {
  constructor(props) {
    this.form = new Vue({
      vuetify,
      ...FormC,
      propsData: props || {}
    }).$mount()
    this.fields = this.form.fields
  }

  get() {
    return this.form.$el
  }

  getItem() {
    return this.form.getItem()
  }

  hasErrors() {
    return this.form.hasErrors()
  }
}

export default Form
