<template>
  <div v-show="show">
    <div class="boolean-input-label">
        <span v-if="!optional" class="red--text">*</span> {{ label }}
    </div>
    <div class="grp">
      <div class="boolean-input-icon">
        <div class="center">
          <v-icon>mdi-forum</v-icon>
        </div>
      </div>
      <div class="yes-no">
        <div class="first"
          :class="value ? (readonly ? 'selected-disabled' : 'selected'): ''"
          @click="select(true)"
          dark
        >
          <div class="icon-space">
            {{ titles['true'] }}
          </div>
        </div>
        <div class="second"
          :class="!value ? (readonly ? 'selected-disabled' : 'selected'): ''"
          @click="select(false)">
          <div class="icon-space">
            {{ titles['false'] }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <v-switch
  v-model="value"
  v-show="show"
  :label="label"
  :disabled="readonly"
  :error="error"
  :error-messages="message"
  autocomplete="off"
  /> -->
</template>

<script>
export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: Boolean,
    readonly: Boolean,
    optional: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    titles: {
      type: Object,
      default: () => ({
        true: 'Si',
        false: 'No'
      })
    },
    visibility: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.value = this.default || false
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-image-outline',
      show: this.visibility,
      firstLoad: true
    }
  },
  methods: {
    select(val) {
      if (!this.readonly) {
        this.value = val
      }
    },
    getItem() {
      return { name: this.name, value: this.value }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .boolean-input-label {
    margin-left: 32px;
    // margin-bottom: 2px;
    font-size: 12px;
    font-weight: 500;
  }

  .grp {
    display: flex;
    margin-bottom: 25px;
  }

  .boolean-input-icon {
    margin-right: 9px;
    .center {
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .yes-no {
    height: 50px;
    flex: 1;
    border-bottom: thin solid rgba(0,0,0,.12);
    .selected {
      border-bottom: 2px #0288D1 solid !important;
    }
    .selected-disabled {
      border-bottom: 2px gray solid !important;
    }
    .first {
      float: left;
      width: 50%;
      height: 100%;
    }
    .second {
      float: left;
      width: 50%;
      height: 100%;
    }
    .icon-space {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
</style>
