<template>
  <div>
    <!-- <gmap-autocomplete
      @place_changed="setPlace">
    </gmap-autocomplete> -->
    <div ref="mapGeofence" class="map-size"></div>
    <!-- <gmap-map
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 820px;"
    >
      <gmap-polygon
        :path="path"
        :editable="!readonly"
        :draggable="!readonly"
        @path_changed="pathChange"
      >
      </gmap-polygon>
    </gmap-map> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Geofence',
  props: {
    default: String,
    readonly: Boolean
  },
  data() {
    return {
      map: null,
      polygon: null,
      zoom: 13
    };
  },
  mounted() {
    const center = { lat: this.getUser.latitude, lng: this.getUser.longitude }
    this.map = new window.google.maps.Map(this.$refs.mapGeofence, {
      zoom: this.zoom,
      center
    })
    this.map.addListener('click', (event) => {
      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      this.createPolygon([
        { lat: position.lat - 0.005, lng: position.lng - 0.005 },
        { lat: position.lat - 0.005, lng: position.lng + 0.005 },
        { lat: position.lat + 0.005, lng: position.lng + 0.005 },
        { lat: position.lat + 0.005, lng: position.lng - 0.005 },
      ])
    })
    if (this.default) {
      this.createPolygon(
        this.default.replace('POLYGON((', '').replace('))', '').split(', ').map((a) => {
          const w = a.split(' ')
          return { lat: parseFloat(w[0]), lng: parseFloat(w[1]) }
        })
      )
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  methods: {
    createPolygon(path) {
      if (this.polygon) {
        return
      }
      this.polygon = new window.google.maps.Polygon({
        path,
        editable: !this.readonly,
        draggable: !this.readonly,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      });
      this.polygon.setMap(this.map);
      this.polygon.addListener('rightclick', () => {
        this.polygon.setMap(null)
        this.polygon = null
      })
    },
    getItem() {
      return 'POLYGON((' + this.polygon.getPath().getArray().map((g) => `${g.lat()} ${g.lng()}`).join(', ') + '))'
    },
    pathChange(event) {
      this.geofence = event.getArray().map((a) => ({ lat: a.lat(), lng: a.lng() }))
    },
    hasErrors() {
      if (!this.actualPosition) {
        window.VMA.$emit('SHOW_SNACKBAR', {
          text: 'Debes seleccionar una ubicación',
          color: 'error'
        })
        return true
      }
      return false
    }
  }
};
</script>
<style scoped>
  .pac-target-input {
    height: 50px !important;
    width: 100%;
    font-size: 16px;
    padding: 10px;
  }
  .map-size {
    width: 100%;
    height: 780px;
  }
</style>
