<template>
  <div v-show="show">
    <div @click="select">
      <div class="boolean-input-label">
        {{label}}
      </div>
      <div class="grp">
        <div class="boolean-input-icon">
          <div class="center">
            <v-icon>mdi-palette</v-icon>
          </div>
        </div>
        <div class="yes-no">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="first" :style="'cursor: pointer;' + (value ? 'background-color: ' + value + ';' : '')"></div>
            </template>
            <span>{{ color }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <v-dialog
      scrollable
      persistent
      v-model="dialog"
      overlay-opacity="0.1"
      overlay-color="primary"
      transition="true"
      max-width="350px"
    >
      <v-card>
        <v-card-title>Selecciona un color</v-card-title>

        <v-card-text>
          <v-color-picker
            v-model="color_model"
            class="ma-2"
            :hide-canvas="colors ? true : false"
            :hide-sliders="colors ? true : false"
            :swatches="colors"
            hide-inputs
            show-swatches
            swatches-max-height="300px"
            hide-mode-switch
          ></v-color-picker>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="value = color; dialog = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: String,
    readonly: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    ignoreInForm: Boolean,
    colors: Array,
    visibility: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.value = this.default
    this.color = this.value || '#FF00FF'
  },
  data() {
    return {
      value: null,
      color: null,
      dialog: false,
      message: null,
      error: false,
      icon: 'mdi-image-outline',
      show: this.visibility,
      firstLoad: true,
      tooltip: ''
    }
  },
  computed: {
    color_model: {
      get() {
        return this.color
      },
      set(v) {
        this.color = v
      },
    },
    showColor() {
      if (typeof this.color_model === 'string') return this.color_model

      return JSON.stringify(Object.keys(this.color_model).reduce((color, key) => {
        color[key] = Number(this.color_model[key].toFixed(2))
        return color
      }, {}), null, 2)
    }
  },
  methods: {
    select() {
      if (!this.readonly) {
        this.dialog = true
      }
    },
    getItem() {
      return { name: this.name, value: this.value }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
      this.removeError()
    }
  }
}
</script>

<style lang="scss" scoped>
  .boolean-input-label {
    margin-left: 32px;
    // margin-bottom: 2px;
    font-size: 12px;
  }

  .grp {
    display: flex;
    margin-bottom: 25px;
  }

  .boolean-input-icon {
    margin-right: 9px;
    .center {
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .yes-no {
    height: 50px;
    flex: 1;
    border-bottom: thin solid rgba(0,0,0,.12);
    .first {
      float: left;
      width: 100%;
      height: 100%;
    }
    .second {
      float: left;
      width: 20%;
      height: 100%;
    }
    .icon-space {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
</style>
