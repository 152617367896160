<template>
  <v-card
    class="mx-auto"
    height="400px"
    style="overflow-y: auto; margin-bottom: 28px"
    v-if="show != false"
  >
    <v-toolbar color="secondary" dark fixed dense>
      <v-toolbar-title>{{label}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          flat
          hide-details
          v-model="newOption"
        ></v-text-field>
      <v-btn color="secondary" tile @click="agregar" v-if="this.readonly != true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <list v-bind:items="items" :actions="actions" :getTitle="getTitle"></list>
  </v-card>
</template>

<script>
import List from "@/components/modulo/List.vue";

export default {
  components: {
    list: List,
  },
  props: {
    type: String,
    name: String,
    label: String,
    default: Array,
    readonly: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    schema: Object,
    visibility: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.value = this.default || [];
  },
  data() {
    return {
      newOption: null,
      value: [],
      message: null,
      error: false,
      icon: "mdi-image-outline",
      show: this.visibility,
      firstLoad: true,
      disabled: true,
      dense: false,
      twoLine: true,
      threeLine: false,
      shaped: false,
      flat: false,
      subheader: false,
      inactive: false,
      subGroup: false,
      nav: false,
      avatar: false,
      rounded: false,
      actions: [
        {
          help: "Eliminar",
          color: "red",
          icon: "mdi-delete",
          action: this.eliminar,
          condition: () => this.readonly !== true,
        },
      ],
    };
  },
  methods: {
    getTitle(item) {
      return item.title
    },
    getItem() {
      return { name: this.name, value: this.value };
    },
    removeError() {
      this.error = false;
      this.message = null;
    },
    setError(message) {
      this.error = true;
      this.message = message;
    },
    setVisibility(val) {
      this.show = val || false;
    },
    agregar() {
      if (this.newOption) {
        this.value.push(this.newOption)
        this.newOption = null
      }
    },
    eliminar(item, index) {
      if (this.value.lenght === 1) {
        this.value = []
      } else {
        this.value.splice(index, 1);
      }
    },
  },
  computed: {
    items() {
      return this.value.map((item) => ({ title: item }));
    },
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val);
      } else {
        this.firstLoad = false;
      }
    },
  },
};
</script>
