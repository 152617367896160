<template>
  <v-textarea
    v-model="value"
    :name="name"
    :label="label"
    :error="error"
    :error-messages="message"
    :prepend-icon="icon"
    :append-icon="append_icon"
    :readonly="readonly"
    @change="removeError"
    autocomplete="off"
    v-show="show">
  >
    <template v-slot:label>
      <span v-if="!optional" class="red--text">*</span> {{ label }}
    </template>
  </v-textarea>
</template>

<script>
export default {
  props: {
    name: String,
    label: String,
    default: String,
    readonly: Boolean,
    optional: Boolean,
    append_icon: String,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    visibility: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.value = this.default
  },
  methods: {
    getItem() {
      return { name: this.name, value: this.value || null }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-text',
      show: this.visibility,
      firstLoad: true
    }
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-text-field {
    padding-top: 6px !important;
    margin-top: 4px !important;
  }
</style>
