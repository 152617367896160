<template>
  <div class="separator" v-if="show">
    <v-subheader class="separator-text">
      {{ label }}
    </v-subheader>
  </div>
</template>

<script>

export default {
  props: {
    type: String,
    name: String,
    label: String,
    visibility: {
      type: Boolean,
      default: true
    },
    separator: Boolean
  },
  mounted() {
    if (this.visibility === false) {
      this.show = false
    }
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
    setVisibility(val) {
      this.show = val || false
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
  .separator {
    margin-bottom: 25px;
    .separator-text {
      background-color: rgba(0, 0, 0, 0.1);
      font-size: 1rem;
    }
  }
</style>
