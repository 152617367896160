import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store';
import GeofenceC from './Geofence.vue'

const Geofence = class {
  constructor(props) {
    this.geofence = new Vue({
      vuetify,
      store,
      ...GeofenceC,
      propsData: props || {}
    }).$mount()
  }

  get() {
    return this.geofence.$el
  }

  getItem() {
    return this.geofence.getItem()
  }

  hasErrors() {
    return this.geofence.hasErrors()
  }
}

export default Geofence
