<template>
  <v-menu
    bottom
    :offset-y="true"
    max-height="300"
    style="margin-top: -21px !important; max-height: 300px;"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        v-model="value"
        :type="type"
        :name="name"
        :label="label"
        :error="error"
        :loading="isLoading"
        :error-messages="message"
        :prepend-icon="icon"
        :append-icon="append_icon"
        :disabled="readonly"
        :readonly="readonlyText"
        @change="removeError"
        autocomplete="off"
        ref="autocomplete-text-field"
        v-show="show">
        <template v-slot:label>
          <span v-if="!optional" class="red--text">*</span> {{ label }}
        </template>
      </v-text-field>
    </template>
    <v-list v-if="entries.length > 0">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        @change="changeSelectedItem(selectedItem)"
      >
        <v-list-item
          v-for="item in entries"
          :key="item"
        >
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: [String, Number],
    readonly: Boolean,
    append_icon: String,
    onChange: Function,
    onLoad: Function,
    options: Array,
    forceChange: Boolean,
    decimals: Boolean,
    optional: Boolean,
    delay: Number,
    mask: String,
    time: Boolean,
    autocomplete: Boolean,
    visibility: {
      type: Boolean,
      default: true
    }
  },
  beforeMount() {
    if (this.options) {
      this.entries = this.options
    }
  },
  created() {
    this.value = this.default
  },
  methods: {
    getItem() {
      if (this.value === undefined || this.value === '' || this.type === 'text' || this.type === 'password') {
        return { name: this.name, value: this.value || undefined }
      }
      return {
        name: this.name,
        value: this.decimals ? parseFloat(this.value) : parseInt(this.value)
      }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    loading(val = false) {
      this.isLoading = val
    },
    changeSelectedItem(index) {
      if (index !== undefined) {
        this.preventChange = true
        this.value = this.entries[index]
      }
      this.$refs['autocomplete-text-field'].focus()
    },
    setOptions(options = []) {
      this.entries = options
    }
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: this.type === 'text' ? 'mdi-format-text' : 'mdi-numeric',
      show: this.visibility,
      firstLoad: true,
      isLoading: false,
      writing: null,
      readonlyText: false,
      selectedItem: null,
      entries: [],
      preventChange: false
    }
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange || !this.preventChange) {
        if (this.delay) {
          clearTimeout(this.writing)
          this.writing = setTimeout(() => {
            this.$parent.$parent.onChange(this.name, val)
          }, this.delay);
        } else {
          this.$parent.$parent.onChange(this.name, val)
        }
      } else {
        this.firstLoad = false
        this.preventChange = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-text-field {
    padding-top: 6px !important;
    margin-top: 4px !important;
  }

  .v-menu__content {
    margin-top: -20px !important;
  }
  .v-list-item--active {
    opacity: unset !important;
    color: unset !important;
  }
</style>
