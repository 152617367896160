<template>
  <v-menu ref="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y min-width="290px"
    v-if="show"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        persistent-hint
        v-model="value"
        :name="name"
        :label="label"
        :error="error"
        :error-messages="message"
        @change="removeError"
        readonly
        :disabled="readonly"
        v-on="on"
        :prepend-icon="icon"
        >
        <template v-slot:label>
          <span v-if="!optional" class="red--text">*</span> {{ label }}
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :disabled="readonly"
      ref="picker"
      v-model="value"
      min="1900-01-01"
      locale="es-mx"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    name: String,
    multiple: Boolean,
    label: String,
    default: Date,
    readonly: Boolean,
    optional: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    visibility: {
      type: Boolean,
      default: true
    },
    isForm: {
      default: true
    },
  },
  mounted() {
    if (this.default) {
      this.value = moment(this.default).format('YYYY-MM-DD')
    }
  },
  methods: {
    getItem() {
      return { name: this.name, value: this.value ? moment(this.value).toDate() : null }
    },
    setValue(val) {
      this.value = val
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    val(val = null) {
      if (val != null) {
        this.value = moment(val).format('YYYY-MM-DD')
      }
      return this.value
    }
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-calendar-month-outline',
      show: this.visibility,
      firstLoad: true
    }
  },
  watch: {
    value(val) {
      if (!this.firstLoad && this.isForm) {
        this.$parent.$parent.onChange(this.name, val)
      } else if (!this.isForm) {
        this.setValue(val)
      }
      if (this.firstLoad) {
        this.firstLoad = false
      }
      this.$emit('update', val)
    }
  }
}
</script>

<style scoped>
  .v-text-field {
    padding-top: 6px !important;
    margin-top: 4px !important;
  }
</style>
